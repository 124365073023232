// Here you can add other styles
.DateInput_input {
  font-size: 15px !important;
  padding: 5px 8px 5px !important;
}

.DateInput {
  width: 110px !important;
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}
